import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const botID: string = process.env.VUE_APP_CLIENT_ID || '512333785338216465';
// const host = window.location.host;

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
    },
    {
        path: '/login',
        name: 'Login',
        redirect: (req) => {
            const to = req.query.to ? `&state=${encodeURIComponent(req.query.to as string)}` : '';
            
            window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${botID}&redirect_uri=${encodeURIComponent(process.env.VUE_APP_OAUTH_CALLBACK as string)}&response_type=code&scope=identify%20guilds%20guilds.members.read%20role_connections.write${to}`;
            return ''
        },
    },
    {
        path: '/login/v2',
        name: 'Login V2',
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/connect",
        name: "Discord Verified Roles",
        component: () => import("../views/Connect.vue"),  
    },
    {
        path: "/verify/guild/:guildID/:hash",
        name: "Verification Portal",
        component: () => import("../views/VerifyV2.vue"),
    },


    {
        path: "/disclosure",
        name: "Vulnerability Disclosure",
        component: () => import("../views/Vuln.vue"),  
    },
    
    {
        path: "/status",
        name: "Status",
        component: () => import("../views/Status.vue"),  
    },

    {
        path: "/upgrade",
        name: "Upgrade",
        component: () => import("../views/Upgrade.vue"),
    },
    {
        path: "/callback",
        name: "Oauth Callback",
        component: () => import("../views/Callback.vue"),
    },
    {
        path: "/dashboard",
        name: 'Manage Servers',
        component: () => import("../views/dashboard/user/ServerSelector.vue")
    },
    {
        path: "/dashboard/subscriptions",
        name: 'Manage Subscriptions',
        component: () => import("../views/dashboard/user/Subscriptions.vue")
    },
    {
        path: "/dashboard/:guildID",
        name: 'Guild Overview',
        component: () => import("../views/dashboard/Guild.vue")
    },
    {
        path: "/dashboard/:guildID/premium",
        name: 'Guild Premium',
        component: () => import("../views/dashboard/UpgradeGuild.vue")
    },
    {
        path: "/dashboard/:guildID/verification",
        name: 'Guild Verification',
        component: () => import("../views/dashboard/Verification.vue")
    },
    // {
    //     path: "/dashboard/:guildID/join-requirements",
    //     name: 'Guild Join Requirements',
    //     component: () => import("../views/dashboard/JoinRequirements.vue")
    // },
    {
        path: "/dashboard/:guildID/anti-phishing",
        name: 'Guild Anti-Phishing',
        component: () => import("../views/dashboard/Phishing.vue")
    },
    {
        path: "/dashboard/:guildID/analytics",
        name: 'Guild Analytics',
        component: () => import("../views/analytics/Overview.vue")
    },
    {
        path: "/dashboard/:guildID/audit-log",
        name: 'Guild Audit-log',
        component: () => import("../views/analytics/Audit.vue")
    },
    

    {
        path: "/invite",
        redirect: '',
        beforeEnter: () => {
            window.location.href = `https://discord.com/oauth2/authorize?client_id=${botID}&scope=bot%20applications.commands&permissions=268561430&response_type=code`  ;
        }
    },
    {
        path: "/support",
        redirect: '',
        beforeEnter: () => {
            window.location.href = 'https://discord.gg/captcha';
        }
    },
    {
        path: "/twitter",
        redirect: '',
        beforeEnter: () => {
            window.location.href = 'https://twitter.com/discordcaptcha';
        }
    },
    {
        path: "/vote",
        redirect: '',
        beforeEnter: () => {
            window.location.href =  Math.random() > .1  ? `https://discordbotlist.com/bots/server-captcha-bot/upvote` : `https://top.gg/bot/512333785338216465/vote`;
        }
    },
    {
        path: "/premium",
        name: 'Premium',
        component: () => import("../views/Upgrade.vue")
    },
    {
        path: "/legal",
        redirect: '',
        beforeEnter: () => {
            window.location.href = 'https://privy.gg/legal';
        }
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("../views/404.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return { el: to.hash }
        }
    },
});

export default router;

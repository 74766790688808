import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios"
import VueAxios from "vue-axios"
import "vanilla-hcaptcha";
import VueGtag from 'vue-gtag';

import "./index.css";

let adscript = document.createElement('script');
adscript.setAttribute('src', 'https://cdn.fuseplatform.net/publift/tags/2/2874/fuse.js');
document.head.appendChild(adscript);

const app = createApp(App)
    .use(store)
    .use(VueAxios, axios)
    .use(VueGtag, {
        config: {
            id: 'G-VT1JT14S09'
        }
    }, router)
    .use(router)
    .mount("#app");


axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.Authorization = localStorage.getItem('c_token');
axios.defaults.timeout = 5000;

axios.interceptors.response.use(undefined, function (err) {
    console.log(err);
    
    return new Promise(function (resolve, reject) {
        if (err.response.status === 401) {
            app.$router.push('/dashboard');
        }
        
        throw err;
    });
});
import { createStore } from "vuex";
import router from "../router";

let timeout: ReturnType<typeof setTimeout> | undefined;

interface Notification {
    title: string
    message: string
}

export default createStore({
    state: {
        user: undefined,
        loading: true,
        guildName: '',

        notification: undefined,
    },
    mutations: {
        login(state, user) {
            state.user = user;
        },
        logout(state, stay) {
            state.user = undefined;
            localStorage.removeItem('c_user');
            localStorage.removeItem('c_token');
            if (!stay) router.push('/');
        },
        syncGuilds(state, guilds) {
            if (state.user) {
                // @ts-ignore
                state.user.guilds = guilds;
            }
        },
        toggleLoading(state) {
            state.loading = !state.loading;
        },
        setGuild(state, guildName) {
            state.guildName = guildName;
        },
        notify(state, notification: Notification) {
            // @ts-ignore
            state.notification = notification;
        },
        clearNotification(state) {
            state.notification = undefined;
        }
    },
    actions: {
        notify({ commit }, notification: Notification) {
            commit('notify', notification);

            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = setTimeout(() => {
                commit('clearNotification')
            }, 10 * 1000);
        }
    },
    modules: {},
});

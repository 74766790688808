<template>
    <div>
        <!-- <div class="bg-gray-900 text-white font-semibold text-lg text-center py-4 mx-auto">
            <router-link to="/premium" class="hover:text-discord">Black friday sale is live. Get 50% off →</router-link>
        </div> -->
        <Notification />
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import Notification from './components/Notification.vue';

export default {
    components: {
        Notification,
    },
    async mounted() {
        const user = localStorage.getItem('c_user');
        const token = localStorage.getItem('c_token');

        if (user && token) {
            // Why??
            this.$nextTick(async () => {
                try {
                    const { data, status } = await this.axios.get(`/oauth/user/dashboard`);
                    
                    this.toggleLoading();    
        
                    if (status === 200) {
                        if (data.error) {
                            this.toggleLoading();
                            this.logout();
                            return;
                        }
                        this.login({ ...JSON.parse(user), guilds: data.guilds });
                    } else {
                        this.logout();
                    }
                } catch {
                    this.logout();
                }
            });
        } else {
            this.toggleLoading();
            if (this.$route.path !== '/') this.logout();
        }
    },
    methods: {
        ...mapMutations(['login', 'logout', 'toggleLoading']),
    }
}
</script>

<style>
body {
    width: 100%;
    background-color: #1f2937;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50
}
</style>